<template>
  <div>
    <table-content-partner
      :images="images"
      :list-partner="listPartner"
      :set-toggle-modal-add-edit-discard="setToggleModalAddEditDiscard"
      :get-toggle-modal-add-edit-discard="getToggleModalAddEditDiscard"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import TableContentPartner from '@/components/globals/table/partner/index'

export default {
  components: {
    TableContentPartner
  },
  props: {
    images: {
      required: true,
      type: String
    },
    listPartner: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      getToggleModalAddEditDiscard: 'partner/get_toggle_modal_add_edit_discard'
    })
  },
  methods: {
    ...mapMutations({
      setToggleModalAddEditDiscard: 'partner/SET_TOGGLE_MODAL_ADD_EDIT_DISCARD'
    })
  }
}
</script>
